// Scripts librairies
import { docReady } from '../../js/libs/utils.js'

// Styles
import '../../scss/themes/services-entreprise/bootstrap.scss' // bootstrap => customisez bootstrap directement

// Charge la base commune
require('../../common.js')

docReady(function () {
  // console.log('Services aux entreprise app template')
})
